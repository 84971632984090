<template>
  <vx-card :title="title">
    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button
          class="my-2"
          type="border"
          color="danger"
          v-on:click="handleClose"
          >Back</vs-button
        >
      </div>
    </div>
    <div class="vx-row mt-3">
      <!-- Customer Data -->
      <div class="vx-col sm:w-1/2 w-full mb-base">
        <span class="py-2 h5 font-semibold">Customer Data</span><hr>
        <!-- One time cust -->
        <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3">
            One Time
          </div>
          <div class="vx-col sm:w-2/6">
            <vs-switch
              color="dark"
              icon-pack="feather"
              vs-icon-on="icon-check-circle"
              vs-icon-off="icon-slash"
              v-model="isOneTime"
              disabled="true"
            >
              <span slot="on">YES</span>
              <span slot="off">NO</span>
            </vs-switch>
          </div>
        </div>
        
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Ship to</span>
          </div>
          <div v-if="!this.isOneTime" class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              name="billto"
              v-model="selectedShippTo"
              :options="optionShippTo"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :searchable="true"
              :internal-search="true"
              :max-height="200"
              :limit="5"
              :disabled="true"
              v-validate="'required'"
              placeholder="Type to search"
              :custom-label="customLableShippTo"
            />
            <span
            class="text-danger text-sm"
            v-show="true"
            >{{ msgErrorShippTo }}</span>
          </div>
          <div v-else class="vx-col sm:w-2/3 w-full">
            <vs-input class="w-full" v-model="oneTimeCust.address_manual" disabled/>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Bill to</span>
          </div>
          <div v-if="!this.isOneTime" class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedBillTo"
              :options="optionBillTo"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :searchable="true"
              :internal-search="true"
              :max-height="200"
              :limit="5"
              :disabled="true"
              placeholder="Type to search"
              :custom-label="customLableBillto"
            />
            <span
            class="text-danger text-sm"
            v-show="isErrorBillTo"
            >{{ msgErrorBillto }}</span>
          </div>
          <div v-else class="vx-col sm:w-2/3 w-full">
            <vs-input class="w-full" v-model="oneTimeCust.address_manual" disabled/>
          </div>
        </div>
        <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Customer</span>
          </div>
          <div v-if="!this.isOneTime" class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedCustomer"
              :options="optionCustomer"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :searchable="true"
              :loading="isLoadingCus"
              :internal-search="false"
              :max-height="200"
              :limit="5"
              :disabled="true"
              placeholder="Type to search"
              @input="onSelectedCus"
              @search-change="handlerSearchCustomer"
              :custom-label="customLableCustomer"
            />
          </div>
          <div v-else class="vx-col sm:w-2/3 w-full">
            <vs-input class="w-full" v-model="selectedCustomer.code" disabled/>
          </div>
        </div>
        <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Customer Name</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-input class="w-full" v-model="selectedCustomer.name" disabled/>
          </div>
        </div>
        <!-- Cust Deposit Fields, for CBD type -->
        <!-- showCustDeposit -->
        <div v-if="showCustDeposit" class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Customer Deposit</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedCustDeposit"
              :options="optionCustDeposit"
              :multiple="true"
              :allow-empty="false"
              track-by="ID"
              :max-height="200"
              placeholder="Type to search"
              @input="onSelectedCustDeposit"
              :custom-label="customLableCustDeposit"
            />
          </div>
        </div>
        <!-- Customer One time Form -->
        <div v-if="this.isOneTime && showOTCForm" class="vx-row mb-2 mt-4">
          <div class="vx-col w-full">
            <span class="py-2 h5 font-semibold">One Time Customer Data</span>
            <!-- <span role="button" class="float-right" @click="handleCloseOt">Close</span> -->
            <hr>
          </div>
          <div class="vx-col w-full">
            <div class="vx-row my-6">
              <div class="vx-col w-full">
                <label class="vs-input--label">Customer Name <span class="text-danger">*</span></label>
                <vs-input class="w-full" v-model="oneTimeCust.name" placeholder="Input name" name="Customer Name" @change="updOtCust()"/>
              </div>
            </div>
            <address-component
              :data="oneTimeCust.address"
              @data="setDataAddress"
            ></address-component>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <label class="vs-input--label">Ship / Bill Address <span class="text-danger">*</span></label>
                <vs-input class="w-full" v-model="oneTimeCust.address_manual" placeholder="Re-input address"/>
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <label class="vs-input--label">Contact Person <span class="text-danger">*</span></label>
                <vs-input class="w-full" v-model="oneTimeCust.contact_person" placeholder="Input contact person"/>
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <label class="vs-input--label">NIK <span class="text-danger">*</span></label>
                <vs-input class="w-full" v-model="oneTimeCust.nik" placeholder="Input NIK"/>
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <label class="vs-input--label">Phone Number <span class="text-danger">*</span></label>
                <vs-input class="w-full" v-model="oneTimeCust.phone" placeholder="Input phone number"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- </div> -->
      <!-- Segmentation Data -->
      <div class="vx-col sm:w-1/2 w-full mb-base">
        <span class="py-2 h5 font-semibold">Segmentation Data</span><hr>
        <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Territory</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedTerritory"
              :options="optionTerritory"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="200"
              name="territory"
              :limit="5"
              :disabled="true"
              @input="onselectedTerritory"
              placeholder="Type to search"
              :searchable="true"
              :custom-label="customLableTerritory"
            />
          </div>
        </div>
        <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Zone</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              v-model="zone.selected"
              :options="zone.option"
              placeholder="Type to search"
              track-by="name"
              :disabled="true"
              label="name"
              :max-height="125"
            >
              <span slot="noResult">Oops! No data found</span>
              <template slot="singleLabel" slot-scope="props">
                <span class="option__desc">
                  <span class="option__title">
                    {{ props.option.code }} {{ props.option.name }}
                  </span>
                </span>
              </template>
              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <span class="option__title">
                    {{ props.option.code }} {{ props.option.name }}
                  </span>
                </div>
              </template>
            </multiselect>
          </div>
        </div>
        
        <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Sales Channel</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="salesChannel.selected"
              :options="salesChannel.option"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="120"
              :limit="3"
              placeholder="Type to search"
              track-by="code"
              :disabled="true"
              label="name"
            >
              <template slot="singleLabel" slot-scope="props">
                <span class="option__desc">
                  <span class="option__title">{{ props.option.name }}</span>
                </span>
              </template>
              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <span class="option__title">{{ props.option.name }}</span>
                </div>
              </template>
            </multiselect>
          </div>
        </div>
        <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Distribution Channel</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="customerCategory.selected"
              :options="customerCategory.option"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="120"
              :disabled="true"
              :limit="3"
              placeholder="Type to search"
              track-by="ID"
              label="name"
            >
              <template slot="singleLabel" slot-scope="props">
                <span class="option__desc">
                  <span class="option__title">{{ props.option.name }}</span>
                </span>
              </template>
              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <span class="option__title">{{ props.option.name }}</span>
                </div>
              </template>
            </multiselect>
          </div>
        </div>
        <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Sales ID</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="salesPersonal.selected"
              :options="salesPersonal.option"
              :multiple="false"
              :allow-empty="true"
              :disabled="true"
              :max-height="120"
              :limit="3"
              placeholder="Type to search"
              track-by="ID"
              label="Name"
            >
              <template slot="singleLabel" slot-scope="props">
                <span class="option__desc">
                  <span class="option__title">{{ props.option.Name }}</span>
                </span>
              </template>
              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <span class="option__title">{{ props.option.Name }}</span>
                </div>
              </template>
            </multiselect>
          </div>
        </div>
        <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Pricing Group</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              v-model="pricingGroup.selected"
              :options="pricingGroup.options"
              placeholder="Type to search"
              track-by="name"
              label="name"
              :disabled="true"
              :max-height="125"
            >
              <span slot="noResult">Oops! No data found</span>
              <template slot="singleLabel" slot-scope="props">
                <span class="option__desc">
                  <span class="option__title">
                    {{ props.option.name }} ({{ props.option.code }})
                  </span>
                </span>
              </template>
              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <span class="option__title">
                    {{ props.option.name }} ({{ props.option.code }})
                  </span>
                </div>
              </template>
            </multiselect>
          </div>
        </div>
      </div>
    </div>
    <!-- 2nd row : SO Data & Delivery Data -->
    <div class="vx-row">
      <!-- SO Data -->
      <div class="vx-col sm:w-1/2 w-full mb-base">
        <span class="pt-2 h5 font-semibold">CO Data</span><hr>
        <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>CO Code</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-input class="w-full" v-model="dataCustomerOrder.code" disabled/>
          </div>
        </div>
        <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Ref. PO Code</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-input class="w-full" v-model="poRefCode" disabled/>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>CO Type</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="soType"
              :options="optionSoType"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="120"
              :limit="4"
              :disabled="true"
              placeholder="Type to search"
              track-by="Code"
              :custom-label="customLableCOType"
            >
            </multiselect>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full flex items-center">
            <span>Date</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <datepicker
              name="date"
              :inline="false"
              v-model="dateCo"
              placeholder="Select Date"
              v-validate="'required'"
              :disabledDates="disabledDates"
              :disabled="true"
            ></datepicker>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full flex items-center">
            <span>PO Date</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <datepicker
              name="date"
              :inline="false"
              v-model="po_date"
              placeholder="Select Date"
              v-validate="'required'"
              :disabledDates="disabledDates"
              :disabled="true"
            ></datepicker>
          </div>
        </div>
        <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Payment Term</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="paymentTerm.selected"
              :options="paymentTerm.option"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="120"
              :limit="3"
              placeholder=" Type to search"
              track-by="id"
              label="name"
              :disabled="true"
              @input="onselectedPaymentTerm"
            >
              <template slot="singleLabel" slot-scope="dt">
                <span class="option__desc">
                  <span class="option__title">{{ dt.option.name }}</span>
                </span>
              </template>
              <template slot="option" slot-scope="dt">
                <div class="option__desc">
                  <span class="option__title">{{ dt.option.name }}</span>
                </div>
              </template>
            </multiselect>
          </div>
        </div>
      </div>
      <!-- Delivery Data -->
      <div class="vx-col sm:w-1/2 w-full mb-base">
        <span class="pt-2 h5 font-semibold">Delivery Data</span><hr>
        <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Allow Partial</span>
          </div>
          <div class="vx-col vx-row sm:w-2/3 w-full">
            <div class="vx-col sm:w-1/6">
              <input type="radio" value="1" v-model="allowPartial">
              <label> Yes</label>
            </div>
            <div class="vx-col sm:w-1/6">
              <input type="radio" value="0" v-model="allowPartial">
              <label> No</label>
            </div>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Delivery Type</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="deliveryType.selected"
              :options="deliveryType.option"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="120"
              :limit="3"
              placeholder=" Type to search"
              track-by="id"
              label="name"
              :disabled="true"
            >
              <template slot="singleLabel" slot-scope="dt">
                <span class="option__desc">
                  <span class="option__title">{{ dt.option.name }}</span>
                </span>
              </template>
              <template slot="option" slot-scope="dt">
                <div class="option__desc">
                  <span class="option__title">{{ dt.option.name }}</span>
                </div>
              </template>
            </multiselect>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full flex items-center">
            <span>Request Delivery Date</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <datepicker
              :inline="false"
              v-model="rDeliveryDate"
              placeholder="Select Date"
              v-validate="'required'"
              :disabledDates="disabledDates"
              :disabled="true"
            ></datepicker>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full flex">
            <span>Note</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-textarea 
              v-model="note"
              height="110px"
              :disabled="true"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- CO Line baru -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <!-- table lawas -->
        <!-- <table class="table table-stripe" style="width:100%"> -->
        <table class="table table-bordered" style="width:100%">
          <thead>
            <tr>
              <th style="vertical-align: middle; width:30%;" rowspan="2">SKU Code</th>
              <th style="vertical-align: middle; width:10%;" rowspan="2">HU Label</th>
              <th style="text-align:center; width:15%;" colspan="2">Qty</th>
              <th style="vertical-align: middle; text-align:center;" colspan="3">List Price</th>
              <!-- Hide discount -->
              <!-- <th style="vertical-align: middle; text-align:center;" rowspan="2">Reg. Discount</th> -->
              <th style="vertical-align: middle; text-align:center; max-width:70px;" rowspan="2">Rounding</th>
              <th style="vertical-align: middle; text-align:center;" rowspan="2">Tax</th>
              <th style="vertical-align: middle; text-align:center;" rowspan="2">Grand Total</th>
              <!-- <th style="vertical-align: middle; text-align:center;" rowspan="2">Promotion</th> -->
              <th style="vertical-align: middle; text-align:center;" rowspan="2">Action</th>
            </tr>
            <tr>
              <th style="text-align:center;">HU</th>
              <th style="text-align:center;">UOM</th>
              <th style="text-align:center;">Unit</th>
              <th style="text-align:center;">Price</th>
              <th style="text-align:center;">Subtotal</th>
            </tr>
          </thead>
          <tbody>
            
            <!-- New layout -->
            <template v-for="(dt, index) in table.data">
              <!-- <vs-tr :key="index" v-for="(dt, index) in 3"> -->
              <tr :key="index">
                <td style="align-item-center; vertical-align: middle; width: 300px; max-width: 400px;" :rowspan="dt.list_price.length+1">
                  <!-- <div :class="''" :key="index2" v-for="(dt2, index2) in dt.formInputs"> -->
                  <div :class="''">
                    <FormInput
                      :components="dt.formInputs[0]" 
                      @onSelected="onselected(dt.formInputs[0].value, index)" 
                      @search-change="handlerSearchSelect"
                    /> 
                  </div>
                </td>
                <td v-if="dt.hu_labels.length > 0" style="vertical-align: middle;" :rowspan="dt.list_price.length+1">
                  <span :key="i" v-for="(dt, i) in dt.hu_labels">
                    {{ dt["unit"] + "." }}
                  </span>
                </td>
                <td v-else style="vertical-align: middle;" :rowspan="dt.list_price.length+1">
                  {{ " - " }}
                </td>
                <td style="vertical-align: middle;" :rowspan="dt.list_price.length+1">
                  <!-- LAYOUT GOOD -->
                  <div>
                    <vs-row vs-justify="center">
                      <vs-col v-for="(q, ix) in dt.items_qty_hu" :key="ix" vs-type="flex" :vs-w="dt.colWidth" vs-justify="center" vs-align="center">
                        <vs-input v-model="dt.items_qty_hu[ix]" type="text" @change="calc_itemsLineQtyHU(index, ix)"></vs-input>
                      </vs-col>
                    </vs-row>
                  </div>
                </td>
                <!-- <td style="vertical-align: middle;"> -->
                  <!-- <vs-input v-model="dt.items_hu" class="w-full" placeholder="" @input="calc_itemsLineQty(index)"/> -->
                <!-- </td> -->
                <td style="vertical-align: middle; padding-right: 25px;" :rowspan="dt.list_price.length+1">
                  <vs-input-number v-model="dt.items_qty" class="w-full" placeholder="" @input="calc_itemsLineQtyConv(index)"/>
                </td>
                <td v-if="dt.list_price.length == 0" style="vertical-align: middle;"></td>
                <td v-if="dt.list_price.length == 0" style="vertical-align: middle;"></td>
                <td v-if="dt.list_price.length == 0" style="vertical-align: middle;"></td>
              <!-- Hide discount -->
                <!-- <td v-if="dt.list_price.length == 0" style="vertical-align: middle;"></td> -->
                <td v-if="dt.list_price.length == 0" style="vertical-align: middle;"></td>
                <td v-if="dt.list_price.length == 0" style="vertical-align: middle;"></td>
                <td v-if="dt.list_price.length == 0" style="vertical-align: middle;"></td>
              </tr>
              <!-- Loops based on list_price -->
              <template v-for="(lp, i) in table.data[index].list_price">
                <tr :key="index+'-'+i">
                  <td class="td vs-table--td" style="vertical-align: middle; text-align:right;">
                      {{ dt.qty_hu[i] + ' (' + lp.unit + ')' }} <br>
                  </td>
                  <td class="td vs-table--td" style="vertical-align: middle; text-align:right;">
                      {{ '@' + formatCurrency(lp.price) }} <br>
                  </td>

                  <td class="td vs-table--td" style="vertical-align: middle; text-align:right;">
                      {{ formatCurrency(dt.subtotal.price[i]) }} <br>
                  </td>
                  <td style="vertical-align: middle; text-align:center;">
                    <div>
                      <vs-row vs-justify="center">
                        <vs-col vs-justify="center" vs-align="center">
                          <vs-input v-model="lp.rounding" type="text" @input="onInputRounding(index, i)" style="max-width: 75px;"></vs-input>
                        </vs-col>
                      </vs-row>
                    </div>
                  </td>
                  <td class="td vs-table--td" style="vertical-align: middle; text-align:right;">
                      {{ formatCurrency(dt.subtotal.tax[i]) }} <br>
                  </td>
                  <td class="td vs-table--td" style="vertical-align: middle; text-align:right;">
                      {{ formatCurrency(dt.grand_total[i]) }} <br>
                  </td>
                  <td style="vertical-align: middle;">
                    <div v-if="i == 0" style="align-items: center; vertical-align: middle;">
                        <vx-tooltip text="Delete Item">
                          <vs-button
                            size="small"
                            color="red"
                            icon-pack="feather"
                            icon="icon-trash"
                            @click="handleRemoveItem(index)"
                          />
                        </vx-tooltip>
                      </div>
                  </td>
                </tr>
              </template>
            </template>
            <tr v-if="this.calc_resp != null && table.data.length > 0">
              <td colspan="6" style="vertical-align: middle; text-align:right;"> <b>Total</b> </td>
              <td style="vertical-align: middle; text-align:right;"> <b>{{ formatCurrency(this.calc_resp.total_netto.toFixed(2)) }}</b> </td>
              <!-- Hide discount -->
              <!-- <td style="vertical-align: middle; text-align:right;"> <b>{{ formatCurrency(this.CalcRespData.total_discount.toFixed(2)) }}</b> </td> -->
              <td style="vertical-align: middle; text-align:right;"> <b>{{ formatCurrency(this.calc_resp.total_rounding.toFixed(2)) }}</b> </td>
              <td style="vertical-align: middle; text-align:right;"> <b>{{ formatCurrency(this.calc_resp.total_tax.toFixed(2)) }}</b> </td>
              <td style="vertical-align: middle; text-align:right;"> <b>{{ formatCurrency(this.calc_resp.total_billing.toFixed(2)) }}</b> </td>
            </tr>
            <tr v-else>
              <td colspan="6" style="vertical-align: middle; text-align:right;"> <b>Total</b> </td>
              <td style="vertical-align: middle; text-align:right;"> <b>{{ formatCurrency(this.manual_calc_resp.total_netto.toFixed(2)) }}</b> </td>
              <!-- Hide discount -->
              <!-- <td style="vertical-align: middle; text-align:right;"> <b>{{ formatCurrency(this.CalcRespData.total_discount.toFixed(2)) }}</b> </td> -->
              <td style="vertical-align: middle; text-align:right;"> <b>{{ this.manual_calc_resp.total_rounding.toFixed(2) }}</b> </td>
              <td style="vertical-align: middle; text-align:right;"> <b>{{ formatCurrency(this.manual_calc_resp.total_tax.toFixed(2)) }}</b> </td>
              <td style="vertical-align: middle; text-align:right;"> <b>{{ formatCurrency(this.manual_calc_resp.total_billing.toFixed(2)) }}</b> </td>
            </tr>
          </tbody>
        </table>

        <!-- sama dengan create -->
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button v-if="!calculated" class="float-left" type="border" color="primary" @click="handleCalculate()">
          Calculate
        </vs-button>
        <vs-button v-else class="float-left" color="success" type="border" @click="handleSubmit()">
          Submit
        </vs-button>
      </div>
    </div>
  </vx-card>
</template>

<script>
import moment from "moment";
import FormInput from "./form_input.vue"
import Datepicker from "vuejs-datepicker";
import AddressComponent from "@/components/master/Address";
export default {
  components :{
    Datepicker,
    FormInput,
    AddressComponent,
  },

  data() {
    const id = this.$route.params.id;
    const edit = true;

    return {
      coId: id,
      baseUrlUpdate: "/api/sfa/v1/customer-order/update/"+id,
      baseUrl: "/api/sfa/v1/customer-order/edit/"+id,
      detailShow: "core vx-col md:w-1/2 w-full mb-base ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,
      isErrorBillTo:false,
      msgErrorBillto:"",
      isErrorShippTop:false,
      msgErrorShippTo:"",

      editCus:edit,
      editBillTo:edit,
      editShippTo:edit,
      editDate:edit,
      editReqDelDateTo:edit,
      editDueDate:edit,
      editItem:edit,

      countItem:0,
      allowPartial: 0,
      poRefCode: null,

      deleteId: null,
      optionTerritory: [],
      optionCustomer: [],
      optionBillTo: [],
      optionShippTo: [],
      isLoadingCus:false,
      selectedTerritory: {
        id:null
      },
      selectedCustomer: {
        code:"",
        name:""
      },
      selectedBillTo: {
        id:null,
        CustomerID:"",
        Address:"",
      },
      selectedShippTo: {
        id:null,
        CustomerID:"",
        Address:"",
      },
      searchCust:"",
      searchItem:"",
      dataCustomerOrder:{},
      dataCustomerOrderDeposit:[],
      dataCustomerOrderLine:{},
      table: this.tableDefaultState(),
      dateCo:null,
      po_date:null,
      duedate:null,
      note:null,
      rDeliveryDate:null,
      disabledDates:null,
      title:"Form sales-order",
      id: id,

      isOneTime: false,
      sameAddress: false,
      oneTimeCust: {
        id:null,
        customer:null,
        name:null,
        address_manual: null,
        address: {
          address: "",
          postalCode: "",
          countryName: "",
          provinceName: "",
          cityName: "",
          districtName: "",
          subdistrictName: "",
        },
        contact_person:null,
        nik:null,
        phone:null,
        payment_method_id:null,
        tax_id:null,
      },
      pricingGroup: {
        selected: null,
        options: [],
      },
      update: false,
      calculated: false,
      optionSoType: [],
      soType: null,
      zone: {
        selected: null,
        option: [],
      },
      salesChannel: {
        selected: null,
        option: [],
      },
      customerCategory: {
        selected: null,
        option: [], 
      },
      salesPersonal: {
        selected: null,
        option: [], 
      },
      paymentTerm: {
        selected: null,
        option: [], 
      },
      deliveryType: {
        selected: null,
        option: [],
      },
      showOTCForm: false,
      calc_resp: null,
      manual_calc_resp: {
        total_netto:0,
        total_rounding:0,
        total_tax:0,
        total_billing:0,
      },      
      selectedCustDeposit: [],
      optionCustDeposit: [],
      showCustDeposit: false,
    };
  },
  methods: {
    tableDefaultState() {
      return {
        data: [
          {
            id:1,
            formInputs:[
              {
                id:1,
                parentId:1,
                disabled: false,
                validate: "required",
                name: "sku_code",
                placeholder: "Sku Code",
                head:"Sku Code",
                type: "multiselect",
                isLoading:false,
                multiple: false,
                allowempty: false,
                value: {},
                optionValue:[],
              },
            ],
            items_hu: '0',
            items_qty: '0',
            hu_labels: [],
            list_price: [],
            reg_disc: [],
            promotion: [],
            tax: [],
            tax_rate: null,
            subtotal: {
              price: [],
              disc: 0,
              tax: [],
              all_price: 0,
              all_tax: 0,
            },
            colWidth: 0,
          }
        ],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalpage: 1,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    handlerSearchCustomer(search){
      this.searchCust = search
      // // console.log("906")
      this.getDataCustomer(this.selectedTerritory)
    },
    getTerritory_() {
      return new Promise((resolve, reject) => {
        this.$http
        .get("/api/sfa/v1/route-plan/territory", {
          params: {
            length: 0,
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionTerritory = resp.data.records;
              if (this.optionTerritory.length > 0) {
                this.selectedTerritory = this.optionTerritory[0];
                if (this.dataCustomerOrder.territory_id) {
                  this.selectedTerritory = this.optionTerritory.filter((v) => {
                    return v.id == this.dataCustomerOrder.territory_id;
                  });
                  this.optionTerritory = this.selectedTerritory; 
                  this.selectedTerritory = this.selectedTerritory[0]; 
                }
              } else {
                this.optionTerritory = [];
                this.selectedTerritory = {};
              }
            } else {
              this.optionTerritory = [];
              this.selectedTerritory = {};
            }
            // console.log("resolve 1 lho - 948")
            resolve("resolve lho")
            // this.$vs.loading.close();
          } else {
            // this.$vs.loading.close();
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Territory option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
          });
            reject("reject lho")
          }
        });
      })
    },
    getOptionZones_() {
      return new Promise((resolve, reject) => {
        this.zone.option = [];
        this.$http.get("api/oms/v1/sfa-sales-order/zone").then((resp) => {
          if (resp.status == "success") {
            this.zone.option = resp.data.records;
            // console.log("resolve 2 lho - 1008")
            resolve("resolve lho")
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Zone option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
            reject("reject lho")
          }
        });
      });
    },
    getOptionSalesChannel_() {
      return new Promise((resolve, reject) => {
        let url = "/api/oms/v1/sfa-sales-order/sales-channel/list";
        this.$http.get(url).then(
          resp => {
            // // console.log("3039",resp)
            if(resp.code == 200){
              this.salesChannel.option = []
              for(var salesChannel = 0; salesChannel<resp.data.length; salesChannel++){
                this.salesChannel.option.push(resp.data[salesChannel])
              }
              this.salesChannel.selected = this.salesChannel.option[0];
              // console.log("resolve 3 lho - 1040")
              resolve("resolve lho")
            }else{
              // this.$router.push("/");
              this.$vs.notify({
                title: "Error",
                text: "Failed to get Sales Channel option",
                color: "danger",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle"
              });
              // console.log(resp);
              reject("reject lho")
            }
          }
        );
      });
    },
    getOptionCustomerCategory_() {
      return new Promise((resolve, reject) => {
        this.$http.get("api/oms/v1/sfa-sales-order/customer-category-all").
        then(resp => {
            if(resp.code == 200){
              this.customerCategory.option = []
              // this.optionCustomerCategory.push({name:'All'})
              for(var customerCategory = 0; customerCategory<resp.data.length;customerCategory++){
                this.customerCategory.option.push(resp.data[customerCategory])
              }
              // this.customerCategory = this.optionCustomerCategory[0];
              this.customerCategory.selected = null;
              // console.log("resolve 4 lho - 1098")
              resolve("resolve lho")
            }else{
              this.$vs.notify({
                title: "Error",
                text: "Failed to get Distribution Channel option",
                color: "danger",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle"
              });
              reject("reject lho")
            }
          }
        );
      });
    },
    getOptionSalesID_() {
      return new Promise((resolve, reject) => {
        let superadmin;
        this.$userLogin.roles.filter(function(item) {
          if(item.Name == "superadmin"){
            superadmin = true;
          }else{
            superadmin = false;
          }
        });
        this.$http.get("/api/oms/v1/sfa-sales-order/sales-order/personals").then(
          resp => {
            if(resp.code == 200){
              this.salesPersonal.option = []
              let data = resp.data.salesman
              for(var idx = 0;idx<data.length;idx++){
                this.salesPersonal.option.push(data[idx])
              }
              if (!superadmin) {
                // set default Sales ID jika bukan superadmin
                this.salesPersonal.selected = this.salesPersonal.option.filter((v) => {
                  return v.ID == this.$userLogin.id;
                });
              }
              // console.log("resolve 5 lho - 1151")
              resolve("resolve lho")
            } else {
              // // console.log(resp.message)
              this.$vs.notify({
                title: "Error",
                text: "Failed to get Salesman option",
                color: "danger",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle"
              });
              reject("reject lho")
            }
          }
        );
      });
    },
    getAllDataPricingGroup_() {
      return new Promise((resolve, reject) => {
        this.$http.get("api/oms/v1/sfa-sales-order/pricing-group", {
          params: {
            order: "name",
            sort: "asc",
          },
        }).then((resp) => {
          if (resp.status == "success") {
            this.pricingGroup.options = resp.data.records;
            // console.log("resolve 6 lho - 1202")
            resolve("resolve lho");
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Pricing Group option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
            reject("reject lho");
          }
        });
      });
    },
    getOptionPaymentTerm_() {
      return new Promise((resolve, reject) => {
        this.$http.get("api/oms/v1/sfa-sales-order/payment-term", {
          params: {
            order: 'name',
            sort: 'asc',
          }
        }).then(resp => {
          if (resp.code == 200) {
            this.paymentTerm.option = resp.data.records;
            this.paymentTerm.selected = {
              id: this.paymentTerm.option[0].id,
              name: this.paymentTerm.option[0].name,
              method_name: this.paymentTerm.option[0].payment_method_name
            };
            // console.log("resolve 7 lho - 1241")
            resolve("resolve lho")
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Payment Term option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-cirlce"
            });
            reject("reject lho")
          }
        });
      });
    },
    getOptionDeliveryType_() {
      return new Promise((resolve, reject) => {
        this.$http.get("api/oms/v1/sfa-sales-order/delivery-type", {
          params: {
            order: 'id',
            sort: 'asc',
          }
        }).then(resp => {
          if (resp.code == 200) {
            this.deliveryType.option = resp.data.records;
            this.deliveryType.selected = {
              id: this.deliveryType.option[0].id,
              name: this.deliveryType.option[0].name,
              own_delivery: this.deliveryType.option[0].own_delivery,
              pickup: this.deliveryType.option[0].pickup,
              parcel_services: this.deliveryType.option[0].parcel_services,
              code: this.deliveryType.option[0].code
            };
            // console.log("resolve 8 lho - 1303")
            resolve("resolve lho");
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Delivery Type option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
            reject("reject lho");
          }
          // this.$vs.loading.close();
        });
      });
    },
    onselected(select, i){
      /* 
        cek is duplicate items? if not
        ------------------------------
        get HU items 
        set hu labels
        set forminput HU dan UOM
      */
      let checkItem, is_duplicate = false;
      // check duplicate items
      if(this.table.data.length > 1){
        this.table.data.forEach((item, j) => {
          checkItem = item.formInputs[0].value;
          if(checkItem.code == select.code && i != j){
            is_duplicate = true;
          }
        });
      }
      // // console.log(currentItem, select)
      if(is_duplicate){
        // if duplicate, value item dinolkan, dan etc" nya juga dinolkan
        this.table.data[i].formInputs[0].value = {};
        this.table.data[i].items_qty_hu = [];
        this.table.data[i].items_hu = '0';
        this.table.data[i].items_qty = '0';
        this.table.data[i].hu_labels = [];
        this.table.data[i].list_price = [];
        this.table.data[i].reg_disc = [];
        this.table.data[i].promotion = [];
        this.table.data[i].tax = [];
        this.table.data[i].tax_rate = null;
        this.table.data[i].subtotal = { price: [], disc: 0, tax: [], all_price: 0, all_tax: 0, };
        this.$vs.notify({
          title: "Warning",
          text: "Duplicate item detected!",
          color: "warning",
          position: "top-right"
        });
        this.calculated = false
        return;
      }else{
        this.calculated = false
        this.$http.get("api/oms/v1/sfa-sales-order/item-hu", {
          params: {
            sku_code: select.code,
          },
        }).then((resp) => {
          // // console.log(resp);
          if (resp.status == "success") {
            // set column width property
            // console.log("colwidth before",this.table.data[i].colWidth);
            this.table.data[i].colWidth = (12/resp.data.hu.length);
            // console.log("colwidth after",this.table.data[i].colWidth);
            // 
            // ======= Add items dan HU =======
            // 
            this.table.data[i].hu_labels = resp.data.hu;
            this.table.data[i].tax_rate = resp.data.tax_rate;
            let sumHU = [];
            for (let i = 0; i < resp.data.hu.length; i++) {
              sumHU.push(0);
            }
            this.table.data[i].items_qty_hu = sumHU;
            this.table.data[i].items_hu = sumHU.join('.');
            this.table.data[i].items_qty = 0;
            // reset line items price
            this.table.data[i].list_price = [];
            this.table.data[i].reg_disc = [];
            this.table.data[i].promotion = [];
            this.table.data[i].tax = [];
            // this.table.data[i].tax_rate = null;
            this.table.data[i].subtotal = { price: [], disc: 0, tax: [], all_price: 0, all_tax: 0, };
          }
        });
      }
    },
    onSelectedCus(select){
      this.optionBillTo = select.customer_address
      this.optionShippTo = select.customer_address

      // this.selectedBillTo = this.optionBillTo[0]
      this.selectedBillTo = null
      this.optionBillTo.forEach(bt => {
        if (bt.id == this.dataCustomerOrder.bill_to_id) {
          this.selectedBillTo = bt;
        }
      });
      // this.selectedShippTo = this.optionShippTo[0]
      this.selectedShippTo = null
      this.optionShippTo.forEach(st => {
        if (st.id == this.dataCustomerOrder.ship_to_id) {
          this.selectedShippTo = st;
        }
      });

      // filter zone
      this.zone.selected = this.zone.option.filter((v) => {
        return v.ID == this.selectedShippTo.ZoneID;
      });
      this.zone.option = this.zone.selected;
      this.zone.selected = this.zone.selected[0];
    },
    customLableTerritory({ code, name }) {
      return `${code} ${name}`;
    },
    customLableCustomer({ code, name }) {
      return `${code} - ${name}`;
    },
    customLableBillto({ CustomerID, Address }) {
      return `${CustomerID} - ${Address}`;
    },
    customLableShippTo({ CustomerID, Address }) {
      return `${CustomerID} - ${Address}`;
    },
    customLableCustDeposit({ Code, DepositValue }){
      return `${Code} - Value : ${this.formatCurrency(DepositValue)}`;
    },
    customLableCOType({ Code, Description }){
      return `${Code} - ${Description}`;
    },
    onselectedTerritory(Select){
      // // console.log("1175")
      this.getDataCustomer(Select)
    },
    onselectedPaymentTerm(select){
      // // console.log(select)
      let due_inv_period = select.due_invoice_period
      
      // due date di-hide, maka yang berperan jadi due-date adl rDD
      // let rDeliveryDate = moment(this.rDeliveryDate).format("MM/DD/YYYY");      
      // let duedate = new Date(rDeliveryDate);
      // duedate.setDate( duedate.getDate() + due_inv_period)
      // this.duedate = duedate;

      let coDate = moment(this.dateCo).format("MM/DD/YYYY");      
      let rdd = new Date(coDate);
      rdd.setDate( rdd.getDate() + due_inv_period)
      this.rDeliveryDate = rdd;
    },    
    onSelectedBillto(){
    },
    onSelectedCustDeposit(){
      this.calculated = false;
    },
    handleCalculate(){
      // All condition untuk calculate : customer, shippto, billto, territory, dst harus sudah terisi. Jika belum, return warning.
      let is_complete = true, msg_incomplete = [];

      // // console.log(this.selectedCustomer)
      if(this.selectedCustomer == {} || this.selectedCustomer == null || this.selectedCustomer.code == "" || this.selectedCustomer.code == null){
        is_complete = false;
        msg_incomplete.push("Please select customer !");
      }

      if(this.selectedBillTo.id == null && !this.isOneTime){
        is_complete = false;
        msg_incomplete.push("Please select bill to address !");
      }

      if(this.selectedShippTo.id == null && !this.isOneTime){
        is_complete = false;
        msg_incomplete.push("Please select shipp to address !");
      }

      if(this.selectedTerritory == null){
        is_complete = false;
        msg_incomplete.push("Please select territory !");
      }

      if(this.zone.selected == null){
        is_complete = false;
        msg_incomplete.push("Please select zone !");
      }

      if(this.salesChannel.selected == null){
        is_complete = false;
        msg_incomplete.push("Please select sales channel !");
      }

      if(this.customerCategory.selected == null){
        is_complete = false;
        msg_incomplete.push("Please select distribution channel !");
      }

      // // console.log(this.salesPersonal)
      if(this.salesPersonal.selected == null || this.salesPersonal.selected.length == 0){
        is_complete = false;
        msg_incomplete.push("Please select salesman !");
      }

      if(this.pricingGroup.selected == null){
        is_complete = false;
        msg_incomplete.push("Please select pricing group !");
      }

      if(this.paymentTerm.selected == null){
        is_complete = false;
        msg_incomplete.push("Please select payment term !");
      }

      let complete_item = true;
      this.table.data.forEach(td => {
        if(td.formInputs[0].value.code == undefined){
          complete_item = false;
        }
      });

      if(!complete_item){
        is_complete = false;
        msg_incomplete.push("Please select item !");
      }

      if(!is_complete){
        msg_incomplete.forEach(msg => {
          this.$vs.notify({
            title: "Warning",
            text: msg,
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-alert-circle"
          });
        });
        this.$vs.notify({
          title: "Warning",
          text: "Please fill the form correctly!",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle"
        });
        this.calculated = false;
        return;
      }

      let params = {
        sales_channel_code: this.salesChannel.selected.code,
        territory_code: this.selectedTerritory.code,
        company_code: this.$store.state.user.currentUser.company_code,
        company_id: this.$store.state.user.currentUser.company_id,
        customer_code: this.isOneTime ? this.oneTimeCust.customer.code : this.selectedCustomer.code,
        pricing_group_id: this.pricingGroup.selected == null ? 0 : this.pricingGroup.selected.ID,
        promo_code: [],
        tax_rate: 0,
        subtotal_charge: 0,
        items: [],
        so_type: this.soType.Code
      };

      for (let i = 0; i < this.table.data.length; i++) {
        let _items = this.table.data[i].formInputs[0].value;
        let items_qty_hu = this.table.data[i].items_qty_hu;
        // let qty_per_HU = this.table.data[i].items_hu.split('.');
        for (let j = 0; j < this.table.data[i].hu_labels.length; j++) {
          // if( parseInt(qty_per_HU[j]) > 0 ){
          if( parseInt(items_qty_hu[j]) > 0 ){
            let temp_item = {
              item_code : _items.code,
              item_name : _items.sku_name,
              item_uom : this.table.data[i].hu_labels[j].unit,
              item_quantity_request : 0,
              item_quantity_adjusted : parseInt(items_qty_hu[j]),
              item_price : 0,
              rounding : this.table.data[i].list_price.length == 0 || this.soType.Code == 'ZDSM' ? 0 : parseFloat(this.table.data[i].list_price[j].rounding),
            }
            if(this.soType.Code == 'ZDSM'){
              this.table.data[i].list_price[j].rounding = 0;
            }
            params.items.push(temp_item)
          }
        }
      }
      // // console.log('NEW params',params)

      this.$vs.loading();
      this.$http
      .post("/api/oms/v3/sfa-sales-order/sales-order/calculate", params)
      .then(resp => {
        // // console.log(resp);
        if (resp.code == 200) {

          this.$vs.loading.close();
          var calc_resp = resp.data;
          
          this.calculated = true;
          for (var i = 0; i < calc_resp.pricing.length; i++) {
            
            if(calc_resp.pricing[i].sub_total <= 0 && calc_resp.pricing[i].sub_total_engine <= 0) {
              // alert("ada yang 0")
              // this.calculated = false;
              this.$vs.loading.close();
            }
                        
          }
          
          // Loops price/pricing per SO Line, per HU
          for (let i = 0; i < this.table.data.length; i++) {
            this.table.data[i].list_price = [];
            this.table.data[i].reg_disc = [];
            this.table.data[i].tax = [];
            this.table.data[i].items_qty_hu = [];
            this.table.data[i].qty_hu = [];
            this.table.data[i].subtotal.price = [];
            this.table.data[i].subtotal.tax = [];
            this.table.data[i].subtotal.tax_rate = [];
            this.table.data[i].subtotal.disc = [];
            this.table.data[i].subtotal.gross_up = [];
            this.table.data[i].grand_total = [];
            
            let sku = this.table.data[i];
            let warn_item = this.table.data[i].formInputs[0].value
            let all_tax = 0, st_tax = 0, st_price = 0, st_disc = 0, st_gross_up = 0, all_gross_up = 0, temp_listprice = null;
            calc_resp.pricing.forEach(calc => {
              for (let j = 0; j < this.table.data[i].hu_labels.length; j++) {
                if(warn_item.code == calc.code && calc.unit == this.table.data[i].hu_labels[j].unit){ 
                  // calc_resp.pricing[i]
                  temp_listprice = {
                    price: calc.price.toFixed(2),
                    item_name: calc.name,
                    unit: calc.unit,
                    rounding: calc.rounding,
                    gross_up: calc.gross_up,
                  }
  
                  this.table.data[i].list_price.push( temp_listprice );
                  this.table.data[i].reg_disc.push( calc.discountable.toFixed(2) );
                  // this.table.data[i].tax.push(value_tax);
                  this.table.data[i].items_qty_hu.push( calc.qty );
                  this.table.data[i].qty_hu.push( calc.qty );
                  
                  // Promotion nya belum
                  
                  // subtotals 
                  st_price += calc.sub_total;
                  st_disc += calc.sub_total_discountable;
                  st_gross_up += calc.gross_up;
                  
                  // tax per HU
                  // st_tax = calc.sub_total * (this.table.data[i].tax_rate / 100);
                  all_tax += calc.tax;
                  all_gross_up += calc.gross_up;
                  this.table.data[i].subtotal.tax.push( calc.tax.toFixed(2) );
                  this.table.data[i].subtotal.tax_rate.push( calc.tax_rate );
                  
                  // subtotal itemline per HU
                  this.table.data[i].subtotal.price.push( calc.sub_total.toFixed(2) );
                  this.table.data[i].subtotal.disc.push( calc.discountable.toFixed(2) );
                  this.table.data[i].grand_total.push( (calc.sub_total - calc.discountable + calc.tax).toFixed(2) );
                }
                if (warn_item.code == calc.code && calc.unit != this.table.data[i].hu_labels[j].unit && sku.items_qty_hu[j] <= 0 ) {
                  temp_listprice = {
                    price: (0).toFixed(2),
                    item_name: warn_item.name,
                    unit: this.table.data[i].hu_labels[j].unit,
                    rounding: 0,
                    gross_up: 0
                  }
  
                  this.table.data[i].list_price.push( temp_listprice );
                  this.table.data[i].reg_disc.push( (0).toFixed(2) );
                  // this.table.data[i].tax.push(value_tax);
                  this.table.data[i].items_qty_hu.push( 0 );
                  this.table.data[i].qty_hu.push( 0 );
                  
                  this.table.data[i].subtotal.tax.push( (0).toFixed(2) );
                  this.table.data[i].subtotal.tax_rate.push( calc.tax_rate );
                  
                  // subtotal itemline per HU
                  this.table.data[i].subtotal.price.push( (0).toFixed(2) );
                  // this.table.data[i].subtotal.disc.push( calc.sub_total_discountable.toFixed(2) );
                  this.table.data[i].subtotal.disc.push( (0).toFixed(2) );
                  // this.table.data[i].grand_total.push( (calc.sub_total - calc.sub_total_discountable + calc.tax).toFixed(2) );
                  this.table.data[i].grand_total.push( (0).toFixed(2) );
                }

              }
              this.table.data[i].subtotal.gross_up.push(st_gross_up.toFixed(2))
            });
            
            // console.log(st_price)

            if(st_price <= 0){
                this.calculated = false;
                this.$vs.notify({
                  title: "Error",
                  text: "Please add qty or set price for item : " + warn_item.sku_name,
                  color: "danger",
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-x-circle"
                });
            }
            
            this.table.data[i].subtotal.all_price = st_price.toFixed(2);
            this.table.data[i].subtotal.all_tax = all_tax.toFixed(2);
            this.table.data[i].subtotal.all_gross_up = all_gross_up.toFixed(2);
          }
          
          if(calc_resp.total_billing <= 0) {
            // alert("ada yang 0")
            // this.calculated = false;
            this.$vs.loading.close();
          }
        } else {
          this.$vs.notify({
            title: "Error",
            text: "Failed to calculate Sales Order",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle"
          });
          this.calculated = false;
          this.$vs.loading.close();
        }
        if(this.calculated==true) {
          this.calc_resp = resp.data;
          this.$vs.notify({
            title: "Success",
            text: "",
            color: "success",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check"
          });
        }
        // console.log(this.table.data)
        this.$vs.loading.close();
      });

    },
    onInputRounding(index, i){
      // console.log("Disini 1647", index, i)
      this.calculated = false;
    },
    handleSubmit() {
      let bst_condition = (this.selectedBillTo !=undefined && this.selectedShippTo!=undefined);
      let ot_address_condition = (this.isOneTime && this.oneTimeCust.address_manual != null);
      if (bst_condition || ot_address_condition) {
        bst_condition = (this.selectedBillTo.id && this.selectedShippTo.id);
        if (bst_condition || ot_address_condition) {
          this.$vs.dialog({
            type: "confirm",
            color: "danger",
            title: `Confirm`,
            text: "Please confirm to submit",
            accept: this.promiseSubmit,
            // accept: this.acceptSubmit,
          });
        } else {
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: "Bill to & Ship to is required",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle"
          });
        }
      }
      // // console.log(this.selectedBillTo)
      if (this.selectedBillTo || ot_address_condition) {
        if (!this.selectedBillTo.id || !ot_address_condition) {
          this.isErrorBillTo =true
          this.msgErrorBillto ="bill to is required "+this.selectedBillTo.id
        }else{
          this.isErrorBillTo=false
          this.msgErrorBillto =""
        }
      }else{
          this.isErrorBillTo =true
          this.msgErrorBillto ="bill to is required "
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: "Bill to is required",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle"
          });
      }
      
      if (this.selectedShippTo || ot_address_condition) {
        if (!this.selectedShippTo.id || ot_address_condition) {
          this.isErrorShippTop =true
          this.msgErrorShippTo ="shipp to is required "+this.selectedShippTo.id
        }else{
          this.isErrorShippTop=false
          this.msgErrorShippTo =""
        }
      }else{
        this.isErrorShippTop =true
        this.msgErrorShippTo ="shipp to is required "
        this.$vs.notify({
            color: "danger",
            title: "Error",
            text: "Shipp to is required",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle"
          });
      }
    },
    promiseSubmit() {
      this.$vs.loading();
      if (this.isOneTime) {
        Promise.all([
          this.submitCustOnetime()
        ]).then((r) => {
          // console.log(r);
          if(r[0].resp.code == 200) {
            this.acceptSubmit();
          } else {
            this.$vs.loading.close();
          }
          // this.acceptSubmit();
        });
      } else {
        this.acceptSubmit();
      }
    },     
    acceptSubmit() {
      // this.$vs.loading();
      let cout, sum_units, qty_per_HU, item_units, item = []

      // console.log("this.table.data", this.table.data);
      this.table.data.forEach(dt=>{

        sum_units = dt.hu_labels.length;
        qty_per_HU = dt.items_hu.split('.');
        item_units = [];
        let total_rounding = 0, total_gross_up = 0;

        // Loops by lsit_price, price > 0
        dt.list_price.forEach((lp, idx) => {
          cout = 0;
          for (let i = 0; i < sum_units; i++) {
            if(lp.unit == dt.hu_labels[i].unit && qty_per_HU[cout] > 0){ // if qty <= 0 maka tidak dikirimkan ke BE
              item_units.push({
                "discount" : (this.soType != null && this.soType.Code == "ZDSM") ? parseFloat(dt.subtotal.disc[idx]) : 0, // kalo SO type Sample / ZDSM maka discount = total belanjaan, agar nol
                "item_unit_id" : parseInt(dt.hu_labels[i].item_unit_id),
                "item_unit" : dt.hu_labels[i].unit,
                "price" : parseFloat(dt.subtotal.price[idx]),
                "tax" : parseFloat(dt.subtotal.tax[idx]),
                "quantity" : parseFloat(qty_per_HU[cout]),
                "rounding" : parseFloat(lp.rounding),
                "gross_up" : parseFloat(lp.gross_up),
              });
              total_rounding += parseFloat(lp.rounding);
              total_gross_up += parseFloat(lp.gross_up);
            }
            cout++;
          }
        });

        item.push({
          "charge" : 1,
          "combo_id" : 0,
          //"discount" : parseFloat(dt.subtotal.disc), //belum ada diskon, sementara nol aja dulu
          "discount" : (this.soType != null && this.soType.Code == "ZDSM") ? parseFloat( this.CalcRespData.total_discount ) : 0, //belum ada diskon, sementara nol aja dulu || kalo SO type Sample / ZDSM maka discount = total belanjaan, agar nol
          "item_unit_id" : parseInt(dt.hu_labels[sum_units-1].item_unit_id),
          "sku_code" : dt.formInputs[0].value.sku_code,
          "sku_name" : dt.formInputs[0].value.sku_name,
          "item_units" : item_units,
          "price" : parseFloat(dt.list_price[sum_units-1]),
          "quantity" : parseInt(dt.items_qty),
          "tax" : parseFloat(dt.subtotal.all_tax),
          "total_rounding" : parseFloat(total_rounding),
          "total_gross_up" : parseFloat(total_gross_up),
        })
      })
      
      const params = {
        "customer_orders":[
            {
              // "type": this.soType.Code,
              "category": this.soType.Code,
              "note": this.note,
              "allow_partial": parseInt(this.allowPartial),
              "po_ref_code": this.poRefCode,
              "customer_category_id": this.customerCategory.selected.ID,
              "order_from": "normal",
              "payment_term_id": this.paymentTerm.selected.id,
              "request_delivery_date" :this.rDeliveryDate?moment(String(this.rDeliveryDate)).format("YYYY-MM-DD"):null,
              "sales_personal_id": this.salesPersonal.selected.ID,
              "shipTo_id": this.isOneTime ? 0 : this.selectedShippTo.id,
              "source":"sfa-web",
              "status":0,
              "territory_id":this.selectedTerritory.id,
              "time": 1687185097496,
              "billTo_id": this.isOneTime ? 0 : this.selectedBillTo.id,
              "customer_id": this.isOneTime ? this.oneTimeCust.customer.ID : this.selectedCustomer.id,
              "date" :this.dateCo?moment(String(this.dateCo)).format("YYYY-MM-DD"):null,
              "due_date":this.duedate?moment(String(this.duedate)).format("YYYY-MM-DD"):null,
              "Items":item,
              "sales_channel_id": this.salesChannel.selected.ID,
              // "pricing_rule_id": 0, //From backend
              "delivery_type_id": this.deliveryType.selected.id,
              "pricing_group_id": this.pricingGroup.selected.ID,
              "zone_id": this.zone.selected.ID,
              "onetime_customer_id": (this.oneTimeCust.id != null && this.isOneTime) ? this.oneTimeCust.id : 0,
              "all_rounding": parseFloat(this.calc_resp.total_rounding),
              //Based on BRD, if PO date (ex_date) di UI kosong, maka didefault by CO Date - confirmed by Ilham
              // "po_date": this.po_date ? moment(String(this.po_date)).format("YYYY-MM-DD") :  null,
              // "ex_date": this.po_date ? moment(String(this.po_date)).format("YYYY-MM-DD") :  null,
              "ex_date": this.po_date ? moment(String(this.po_date)).format("YYYY-MM-DD") :  moment(String(this.dateCo)).format("YYYY-MM-DD"), 
            }
        ]
      };

      // // console.log(params);
      // return;

      this.$http
        .put(this.baseUrlUpdate, params)
        .then((resp) => {
          this.$vs.loading.close();
          // // console.log(resp.data)
          if (resp.code == 200) {
            let msg ="",title = ""
            
            // // console.log(resp.data[0].status_create )
            if(resp.data[0].status_create){
              let msgArr =[]
              if (resp.data[0].status_create.status=="Error") {
                if (resp.data[0].status_create.messages){
                  msgArr = resp.data[0].status_create.messages
                }
                title = resp.data[0].status_create.State
                msgArr.forEach((dt,i)=>{
                  msg +=dt
                  if ((i+1)<msgArr.length){
                    msg +=", "
                  }
                })
                this.$vs.notify({
                  color: "danger",
                  title: title,
                  text: msg,
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-x-circle",
                });
              }else if (resp.data[0].status_create.status=="Success"){
                this.$vs.notify({
                  color: "success",
                  title: "Success",
                  text: "The data was successfully updated",
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-check-circle",
                });
                this.$router.push({ name: "sales-order" });
              }
              // msg = resp.data[0].StatusCreate.Message
            }
              
          } else {
            if (resp.data) {
              if(resp.data.messages){
                resp.data.messages.forEach(dt => {
                  this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: dt,
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                  });
                });
              }
            }else{
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          // console.log(error);
        });
    },

    handlerSearchSelect(searching,comp) {
      // // console.log(searching, comp)
      this.getItem(searching,this.table.data[comp.parentId-1].formInputs)
    },

    handleAddItem() {
      // // console.log(this.table)
      let i_new = this.table.data.length
      // // console.log(i_new)
      this.table.data.push({
            id:i_new+1,
            formInputs:[
              {
                id:1,
                parentId:i_new+1,
                disabled: false,
                validate: "required",
                name: "sku_code",
                placeholder: "Sku Code",
                head:"Sku Code",
                type: "multiselect",
                multiple: false,
                allowempty: false,
                value: {},
                optionValue:[],
              },
            ],
            items_qty_hu: [],
            items_hu: '0',
            items_qty: '0',
            hu_labels: [],
            list_price: [],
            reg_disc: [],
            promotion: [],
            tax: [],
            tax_rate: null,
            subtotal: {
              price: [],
              disc: 0,
              tax: [],
              all_price: 0,
              all_tax: 0,
            },
          }
        )
        this.getItem(null,this.table.data[i_new].formInputs)
        this.calculated = false;
    },
    handleRemoveItem(index) {
      this.table.data = this.table.data.filter((v, i) => {
          return i != index;
        });
        this.calculated = false;
    },
    handleClose() {
      // back to list
      this.$router.push({ name: "sales-order" });
    },
    showOTForm(){
      this.showOTCForm = true;
    },
    handleCloseOt() {
      this.showOTCForm = false;
    },
    async getDataCustomer(SelectTerritory) {
      this.isLoadingCus =true
      let url = this.isOneTime ? "/api/sfa/v1/customer/" : "/api/sfa/v1/customer/filter";
      let params = {
        length: 1,
        customer_id: this.dataCustomerOrder.customer_id,
      };
      if(this.isOneTime){
        params.search = "ONETIME CUSTOMER"
      }
      await this.$http
        .get(url, {
          params: params
        })
        .then((resp) => {
          this.isLoadingCus =false
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionCustomer = resp.data.records;
              if (this.optionCustomer.length > 0) {
                if (this.editCus) {
                  resp.data.records.forEach((dt,i)=>{
                    if(dt.id==this.dataCustomerOrder.customer_id){
                      this.selectedCustomer = resp.data.records[i]
                    }
                  })

                  this.onSelectedCus(this.selectedCustomer)
                }else{
                  this.optionCustomer = resp.data.records;
                }
              } else {
                this.optionCustomer = [];
                this.selectedCustomer = {};
              }
            } else {
              this.optionCustomer = [];
              this.selectedCustomer = {};
            }
          } else {
            // // console.log(resp.data);
          }
        });
    },
    getItem_(search,comp){
      return new Promise((resolve, reject) => {
        let idx =0
        if (comp) {
          idx = comp[0].parentId-1;
          this.table.data[idx].formInputs[0].isLoading =true
        }else{
          this.table.data.forEach((dt,i) => {
            this.table.data[i].formInputs[0].isLoading =true
          });
        }
        let searchItem =""
        if(search){
          searchItem = search
        }
        this.$http
        .get("api/oms/v1/sfa-sales-order/get-item-available", { params: { limit: 40, offset: 0, search: searchItem, },})
        .then((resp) => {
          // // console.log(resp);
          this.table.data[idx].formInputs[0].isLoading =false
          if (resp.code == 200) {
            let data =[]
            resp.data.records.forEach(dt=>{
              data.push(
                {
                  id:dt.item_id,
                  code:dt.sku_code,
                  text:'[' + dt.sku_code + '] ' + dt.item_name,
                  sku_name:dt.item_name
                }
              )
            })
            if (comp) {
              this.table.data[idx].formInputs[0].optionValue = data
            }else{
              this.table.data.forEach((dt,i) => {
                this.table.data[i].formInputs[0].optionValue  = data
                this.table.data[i].formInputs[0].isLoading    = false
              });
            }
            // console.log("resolve 9 lho - 2143")
            resolve("resolve lho")
          } else {
            // // console.log(resp.data);
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Item option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
            reject("reject lho")
          }
        });
      });
    },
    getItem(search,comp) {
      let idx =0
      if (comp) {
        idx = comp[0].parentId-1;
        this.table.data[idx].formInputs[0].isLoading =true
      }else{
        this.table.data.forEach((dt,i) => {
          this.table.data[i].formInputs[0].isLoading =true
        });
      }
      let searchItem =""
      if(search){
        searchItem = search
      }
      this.$http
      .get("api/oms/v1/sfa-sales-order/get-item-available", { params: { limit: 40, offset: 0, search: searchItem, },})
      .then((resp) => {
        // // console.log(resp);
        this.table.data[idx].formInputs[0].isLoading =false
        if (resp.code == 200) {
          let data =[]
          resp.data.records.forEach(dt=>{
            data.push(
              {
                id:dt.item_id,
                code:dt.sku_code,
                text:'[' + dt.sku_code + '] ' + dt.item_name,
                sku_name:dt.item_name
              }
            )
          })
          if (comp) {
            this.table.data[idx].formInputs[0].optionValue = data
          }else{
            this.table.data.forEach((dt,i) => {
              this.table.data[i].formInputs[0].optionValue  = data
              this.table.data[i].formInputs[0].isLoading    = false
            });
          }
        } else {
          // // console.log(resp.data);
        }
      });
    },
    // customer-onetime
    getOnetimeCustByID(id) {
      let url = "/api/oms/v1/sfa-sales-order/sales-order/onetime_cust/" + id;
      this.$http.get(url).then(resp => {
        // // console.log(url, resp);
        if (resp.code == 200) {
          /* 
            Sukses : 
            - set field cust name, address
            - set all onetime field properties
          */
          this.selectedCustomer.name = resp.data.name;
          // // console.log("reset custData, this.selectedCustomer.name", this.selectedCustomer.name)

          let oneTimeCust = {
            id:resp.data.ID,
            customer:this.selectedCustomer,
            name:resp.data.name,
            address_manual: resp.data.billto_address, //shipto & billto is same address
            address: {
              address: resp.data.billto_address,
              postalCode: resp.data.psotal_code,
              countryName: resp.data.country,
              provinceName: resp.data.province,
              cityName: resp.data.city,
              districtName: resp.data.district,
              subdistrictName: resp.data.sub_district,
            },
            contact_person:resp.data.contact_person,
            nik:resp.data.nik,
            phone:resp.data.phone,
            payment_method_id:resp.data.payment_method_id,
            tax_id:resp.data.tax_id,
          };

          this.oneTimeCust = oneTimeCust;
        } else {
          this.$vs.notify({
            title: "Error",
            text: resp.message,
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle"
          });
        }
        // this.$vs.loading.close();
      });
    },
    getOnetimeCustomer() {
      this.$vs.loading();
      this.$http.get("/api/oms/v1/sfa-sales-order/customer-onetime").then(resp => {
        // // console.log("OT customer", resp);
        if (resp.code == 200) {
          // New holder data
          this.selectedCustomer.code = '[ ' + resp.data.records.code + ' ] ' + resp.data.records.name;
          this.selectedCustomer.name = this.isOneTime ? this.oneTimeCust.name : "";
          // // console.log("reset custData, this.selectedCustomer.name", this.selectedCustomer.name)
          this.oneTimeCust.customer = resp.data.records;
        } else {
          this.$vs.notify({
            title: "Error",
            text: "Failed to get Customer",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle"
          });
        }
        this.$vs.loading.close();
      });
    },
    updOtCust(){
      this.selectedCustomer.name = this.oneTimeCust.name;
    },
    checkFormOnetime(){
      // cek apakah ada field OT customer yang kosong
      let form_empty = false;
      form_empty = (this.oneTimeCust.name == null || this.oneTimeCust.name == "") || form_empty;
      form_empty = (this.oneTimeCust.contact_person == null || this.oneTimeCust.contact_person == "") || form_empty;
      form_empty = (this.oneTimeCust.nik == null || this.oneTimeCust.nik == "") || form_empty;
      form_empty = (this.oneTimeCust.phone == null || this.oneTimeCust.phone == "") || form_empty;
      form_empty = (this.oneTimeCust.address_manual == null || this.oneTimeCust.address_manual == "") || form_empty;
      
      return form_empty;
    },
    submitCustOnetime(){
      return new Promise((resolve, reject) => {
        let isnt_complete = this.checkFormOnetime();
        if(!isnt_complete) {
          let params, url;
          // // console.log(this.oneTimeCust);
          url = this.oneTimeCust.id != null ? 
            "/api/oms/v1/sfa-sales-order/sales-order/onetime_cust/" + this.oneTimeCust.id : 
            "/api/oms/v1/sfa-sales-order/sales-order/onetime_cust/store";
          params = {
            // Default by Customer - ONETIME CUSTOMER
            type: this.oneTimeCust.customer.type,
            customer_id: this.oneTimeCust.customer.ID,
            payment_method_id: this.oneTimeCust.customer.payment_method_id,
            
            // Default init value, updated after SO created
            payment_term_id: this.oneTimeCust.customer.payment_term_id,
            
            // Value by input
            name: this.oneTimeCust.name,
            country: this.oneTimeCust.address.countryName,
            province: this.oneTimeCust.address.provinceName,
            city: this.oneTimeCust.address.cityName,
            district: this.oneTimeCust.address.districtName,
            sub_district: this.oneTimeCust.address.subdistrictName,
            address: this.oneTimeCust.address_manual,
            postal_code: this.oneTimeCust.address.postalCode,
            contact_person: this.oneTimeCust.contact_person,
            nik: this.oneTimeCust.nik,
            phone: this.oneTimeCust.phone,
            tax_id: this.oneTimeCust.tax_id,
          };
          
          // // console.log('params', params)
          this.$http.post(url, params).then(resp => {
            // // console.log(url, resp);
            if (resp.code == 200) {
              /* 
                Sukses 
                1. set column field Customer Name
                2. set column field Bill to
                3. Set column field Ship to 
              */
              this.oneTimeCust.id = this.oneTimeCust.id == null ? resp.data.ID : this.oneTimeCust.id;
              this.selectedCustomer.name = this.oneTimeCust.name;
              
              this.$vs.notify({
                color: "success",
                title: "Success",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-check-circle",
              });
              resolve({
                status: "success submit onetime customer",
                resp: resp
              })
            } else {
              this.$vs.notify({
                title: "Error",
                text: resp.message,
                color: "danger",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle"
              });
              reject({
                status: "failed submit onetime customer",
                url_action: url,
                param: params,
                error: resp.message,
                resp: resp
              })
            }
            // this.$vs.loading.close();
          });
        } else {
          this.$vs.notify({
            title: "Error",
            text: "Please fill customer form correctly !",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle"
          });
          resolve({
            status: "data onetime customer tidak lengkap",
            resp: {code: 500}
          });
        }
      });
    },
    resetOTC_data(){
      let oneTimeCust = {
        id:null,
        customer:null,
        name:null,
        address_manual: null,
        address: {
          address: "",
          postalCode: "",
          countryName: "",
          provinceName: "",
          cityName: "",
          districtName: "",
          subdistrictName: "",
        },
        contact_person:null,
        nik:null,
        phone:null,
        payment_method_id:null,
        tax_id:null,
      };
      this.oneTimeCust = oneTimeCust;
    },
    setDataAddress(val) {
      this.oneTimeCust.address = val;
    },
    calc_itemsLineQty(index){
      // // console.log('index', 1658)
      var uomConv, sumHU, sumUOM = 0;
      sumHU = this.table.data[index].items_hu.split(".");
      uomConv = this.table.data[index].hu_labels;
      
      if(uomConv.length == 0){
        this.table.data[index].items_qty = 0;
        this.$vs.notify({
          title: "Error",
          text: "Select item first !",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle"
        });
        this.table.data[index].items_hu = '0';
        this.table.data[index].items_qty = '0';
        return
      } else if (sumHU.length != uomConv.length){
        this.table.data[index].items_qty = 0;
        this.$vs.notify({
          title: "Error",
          text: "Invalid input !",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle"
        });
        return
      }else{
        for (let i = 0; i < sumHU.length; i++) {
          sumUOM += parseInt(sumHU[i] * parseInt(uomConv[i].amount_uom));
          this.table.data[index].items_qty = sumUOM;
        }
        this.calculated = false;
      }
    },
    calc_itemsLineQtyConv(index){
      // // console.log('index', 1682)
      var uomConv, sumHU, amount_uom, sumUOM = 0;
      let val_input = this.table.data[index].items_qty;
      sumHU = [];
      uomConv = this.table.data[index].hu_labels;
      sumUOM = parseInt(val_input);

      if(uomConv.length == 0){
        this.$vs.notify({
          title: "Error",
          text: "Select item first !",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle"
        });
        this.table.data[index].items_hu = '0';
        this.table.data[index].items_qty = 0;
        return
      }
      
      for (let i = 0; i < uomConv.length; i++) {
        amount_uom = parseInt(uomConv[i].amount_uom);
        if((sumUOM / amount_uom) > 0){
          sumHU.push(parseInt(sumUOM / amount_uom));
          sumUOM = sumUOM % amount_uom;
        }else{
          sumHU.push(0);
        }
      }
      this.calculated = false;
      this.table.data[index].items_hu = sumHU.join('.');
      this.table.data[index].items_qty_hu = sumHU;
      // console.log("this.table.data[index].items_hu", this.table.data[index].items_hu)
    },
    calc_itemsLineQtyHU(index, hu_index){
      // console.log(hu_index)
      var qtyHU, uomConv, sumUOM = 0, lowest_hu;
      uomConv = this.table.data[index].hu_labels;
      qtyHU = this.table.data[index].items_qty_hu;
      lowest_hu = hu_index == (uomConv.length-1) ? true : false;
      if(qtyHU[hu_index] == undefined){
        this.table.data[index].items_qty = 0;
        this.$vs.notify({
          title: "Error",
          text: "Select item first !",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle"
        });
        this.table.data[index].items_hu = '0';
        this.table.data[index].items_qty = '0';
        return
      } else if( isNaN( qtyHU[hu_index]) || qtyHU[hu_index] == '' ) {
        this.table.data[index].items_hu = '0';
        // this.table.data[index].items_qty = '0';
        this.table.data[index].items_qty_hu[hu_index] = '0';
        this.$vs.notify({
          title: "Error",
          text: "Invalid input !",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle"
        });
        return
      }else{
        if(lowest_hu && uomConv.length != 1){        
          qtyHU[hu_index] = parseInt(qtyHU[hu_index], 10);
          qtyHU[hu_index-1] = parseInt(qtyHU[hu_index-1], 10);
          if(qtyHU[hu_index] >= parseInt(uomConv[hu_index-1].amount_uom)){
            let divRes, modRes;
            divRes = qtyHU[hu_index] / parseInt(uomConv[hu_index-1].amount_uom);
            modRes = qtyHU[hu_index] % parseInt(uomConv[hu_index-1].amount_uom);
            // tambah uom atasnya
            qtyHU[hu_index-1] += divRes;
            // ubah input HU sekarang
            qtyHU[hu_index] = modRes;
          }
        }
        for (let i = 0; i < uomConv.length; i++) {
          qtyHU[i] = parseInt(qtyHU[i], 10);
          sumUOM += parseInt(qtyHU[i] * parseInt(uomConv[i].amount_uom));
        }
        this.table.data[index].items_hu = qtyHU.join('.');
        this.table.data[index].items_qty = sumUOM;
        // console.log(this.table.data)
        this.calculated = false;
      }
    },
    formatCurrency(value) {
      if (value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    getSOTypes(){
      return new Promise((resolve, reject) => {
        this.$http.get("api/oms/v1/sfa-sales-order/sales-order-type", {
          params: {
            order: 'name',
            sort: 'asc',
          }
        }).then(resp => {
            // console.log(resp)
            if (resp.code == 200) {
              this.optionSoType = resp.data.records;
              resolve(true);
            } else {
                this.$vs.notify({
                    title: "Error",
                    text: "Failed to get Sales Order Types option",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle"
                });
                reject(false);
            }
        });
      });
    },    
    getCustDeposit(cust_id) {
      this.$vs.loading();
      this.$http.get("api/oms/v1/sfa-sales-order/cash-bank/customer-deposit/" + cust_id).then((resp) => {
        // console.log(resp);
        if (resp.status == "success") {
          if(resp.data.cashBank.length > 0){
            this.optionCustDeposit = resp.data.cashBank;
            this.dataCustomerOrderDeposit.forEach(cdep => {
              this.optionCustDeposit.forEach(v => {
                if(v.ID == cdep.DepositID){
                  this.selectedCustDeposit.push(v);
                }
              });
            });
          } else {
            this.$vs.notify({
              title: "Warning",
              text: "No deposit found ! Please add or create one first !",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-alert-circle"
            });
          }
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    getData_() {
      return new Promise((resolve, reject) => {
        this.$http.get(this.baseUrl).then( async(resp) => {
          // // console.log(resp);
          if (resp.code == 200) {
            this.dataCustomerOrder = resp.data.records
            this.dataCustomerOrderLine = resp.data.records_line
            this.dataCustomerOrderDeposit = resp.data.record_cash_deposits
            this.searchCust =  this.dataCustomerOrder.customer_id
            this.allowPartial = this.dataCustomerOrder.allow_partial
            if (this.editDate) {
              this.dateCo = moment(this.dataCustomerOrder.date).format("DD MMM YYYY")

              this.disabledDates = {
                to: new Date(new Date(this.dataCustomerOrder.date) - 8640000)
              }
            }
            if (this.editDueDate) {
              this.duedate = moment(this.dataCustomerOrder.due_date).format("DD MMM YYYY")
            }
            if (this.editReqDelDateTo) {
              this.rDeliveryDate = moment(this.dataCustomerOrder.request_delivery_date).format("DD MMM YYYY")
            }

            if(this.dataCustomerOrder.ex_date !== undefined){
              this.po_date = moment(this.dataCustomerOrder.ex_date).format("DD MMM YYYY")
            }

            this.note = this.dataCustomerOrder.note

            // set onetime customer
            let onetimeCustId = this.dataCustomerOrder.onetime_customer_id;
            // // console.log("onetimeCustId", onetimeCustId)
            if(onetimeCustId != 0 && onetimeCustId != undefined){
              this.isOneTime = true;
              await this.getOnetimeCustByID(this.dataCustomerOrder.onetime_customer_id);
              await this.getOnetimeCustomer();
              // filter territory
              this.selectedTerritory = this.optionTerritory.filter((v) => {
                return v.id == this.dataCustomerOrder.territory_id;
              });
              this.optionTerritory = this.selectedTerritory;
              this.selectedTerritory = this.selectedTerritory[0];
              // filter zone
              this.zone.selected = this.zone.option.filter((v) => {
                return v.ID == this.dataCustomerOrder.zone_id;
              });
              this.zone.option = this.zone.selected;
              this.zone.selected = this.zone.selected[0];
              // filter sales
              this.salesPersonal.selected = this.salesPersonal.option.filter((v) => {
                return v.ID == this.dataCustomerOrder.sales_personal_id;
              });
              this.salesPersonal.option = this.salesPersonal.selected;
              this.salesPersonal.selected = this.salesPersonal.selected[0];
              // Filter sales channel, berdasarkan salesman
              this.salesChannel.selected = this.salesChannel.option.filter((v) => {
                return v.ID == this.salesPersonal.selected.SalesChannelId;
              });
              this.salesChannel.option = this.salesChannel.selected;
              this.salesChannel.selected = this.salesChannel.selected[0];
              this.customerCategory.selected = this.customerCategory.option.filter((v) => {
                return v.ID == this.dataCustomerOrder.data_json.customer_category_id;
              });
              this.customerCategory.option = this.customerCategory.selected
              this.customerCategory.selected = this.customerCategory.selected[0]

              // filter pricing group, default master Onetime Customer, next set by input / select saat create
              this.pricingGroup.selected = this.pricingGroup.options.filter((v) => {
                return v.ID == this.dataCustomerOrder.pricing_group_id;
              });
              this.pricingGroup.option = this.pricingGroup.selected
              this.pricingGroup.selected = this.pricingGroup.selected[0]
              // filter payment term
              this.paymentTerm.selected = this.paymentTerm.option.filter((v) => {
                return v.id == this.dataCustomerOrder.payment_term_id;
              });
              this.paymentTerm.selected = this.paymentTerm.selected[0];
              this.showOTForm();
            } else {
              // sudah auto set data lainnya via watch, kecuali saat OnetimeCustomer
              // // console.log("2011")
              await this.getDataCustomer(this.selectedTerritory)
            }

            // filter delivery type
            this.deliveryType.selected = this.deliveryType.option.filter((v) => {
              return v.id == this.dataCustomerOrder.delivery_type_id;
            });
            this.deliveryType.selected = this.deliveryType.selected[0]

            this.poRefCode = this.dataCustomerOrder.data_json.po_ref_code;
            this.note = this.dataCustomerOrder.note;
            
            // Kode SO type disimpan di kolom category
            this.soType = this.optionSoType.filter((v) => {
              return v.Code == this.dataCustomerOrder.category;
            });
            this.soType = this.soType[0];

            // Persiapan data lines, extract resp.data.records_line
            let data = [];
            let manual_calc_resp = {
              total_netto: 0,
              total_rounding: 0,
              total_tax: 0,
              total_billing: 0,
            }
            this.dataCustomerOrderLine.forEach((dtitems, i) => {
              // console.log("dtitems", dtitems)
              let tempData = {
                id:i,
                formInputs:[
                  {
                    id:1,
                    parentId:i+1,
                    // disabled: false,
                    disabled: true, //tidak diperbolehkan mengubah existing SKU
                    validate: "required",
                    name: "sku_code",
                    placeholder: "Sku Code",
                    head:"Sku Code",
                    type: "multiselect",
                    multiple: false,
                    allowempty: false,
                    value: {
                      id	      :	dtitems.item_unit.item.id,
                      code	    :	dtitems.item_unit.item.sku_code,
                      text	    :	'[' + dtitems.item_unit.item.sku_code + ']' + dtitems.item_unit.item.name,
                      sku_name	:	dtitems.item_unit.item.name,
                    },
                    optionValue:[],
                  },
                ],
                items_hu: '0',
                items_qty: dtitems.quantity,
                qty_hu: [],
                hu_labels: [],
                list_price: [],
                reg_disc: [],
                promotion: [],
                tax: [],
                grand_total: [],
                tax_rate: dtitems.tax_rate,
                subtotal: {
                  price: [],
                  disc: dtitems.total_discount,
                  tax: [],
                  all_price: 0,
                  all_tax: 0,
                },
              };

              // console.log("dtitems.this_hu", dtitems.this_hu)
              // console.log("dtitems.detail_hu", dtitems.detail_hu)
              let detail_hu = {}, this_hu = {};
              
              for (let n = 0; n < dtitems.detail_hu.length; n++) {
                detail_hu[dtitems.detail_hu[n].unit_name] = dtitems.detail_hu[n]
              }
              
              for (let m = 0; m < dtitems.this_hu.length; m++) {
                this_hu[dtitems.this_hu[m].unit] = dtitems.this_hu[m]
              }

              for (let m = 0; m < dtitems.this_hu.length; m++) {
                let unit = dtitems.this_hu[m].unit, temp_listprice = null
                let detail = detail_hu[unit]
                // console.log("unit", unit)
                // console.log("this_hu[unit]", this_hu[unit])
                // console.log("detail_hu[unit], detail", detail)
                if (detail != undefined) {
                  var hu_price, hu_qty, hu_tax;
                  hu_qty    = detail.quantity;
                  hu_price  = hu_qty != 0 ? detail.price / hu_qty : detail.price;
                  hu_tax    = detail.tax;
                  if(hu_qty > 0){
                    tempData.qty_hu.push(hu_qty)
                    tempData.hu_labels.push({
                      amount_uom: detail.amount_uom,
                      item_unit_id: detail.item_unit_id,
                      unit: detail.unit_name,
                    });

                    temp_listprice = {
                      price: hu_price.toFixed(2),
                      unit: unit,
                      rounding: detail.rounding,
                    }

                    tempData.list_price.push( temp_listprice );
                    tempData.reg_disc.push( detail.discount.toFixed(2) );
  
                    let tax_percent = hu_qty != 0 ? ( hu_tax / hu_price) / hu_qty : 0;
  
                    let st_price = hu_price * detail.quantity;
                    let st_tax = st_price * tax_percent;
                    tempData.subtotal.price.push( st_price.toFixed(2) );
                    tempData.subtotal.tax.push( st_tax.toFixed(2) );
  
                    tempData.subtotal.all_price += st_price;
                    tempData.subtotal.all_tax += st_price * tax_percent;
                    tempData.grand_total.push( (st_price - detail.total_discount + st_tax).toFixed(2) );  
                    
                    // persiapan Total Semua
                    manual_calc_resp.total_netto += st_price;
                    manual_calc_resp.total_rounding += detail.rounding;
                    manual_calc_resp.total_tax += st_tax;
                    manual_calc_resp.total_billing += (st_price - detail.total_discount + st_tax);
                  } else {
                    tempData.qty_hu.push(hu_qty)
                    tempData.hu_labels.push({
                      amount_uom: detail.amount_uom,
                      item_unit_id: detail.item_unit_id,
                      unit: detail.unit_name,
                    });

                    hu_price = 0;
                    detail.discount = 0;
                    temp_listprice = {
                      price: hu_price.toFixed(2),
                      unit: unit,
                      rounding: 0,
                    }

                    tempData.list_price.push( temp_listprice );
                    tempData.reg_disc.push( detail.discount.toFixed(2) );
  
                    let tax_percent = 0;
  
                    let st_price = hu_price * detail.quantity;
                    let st_tax = st_price * tax_percent;
                    tempData.subtotal.price.push( st_price.toFixed(2) );
                    tempData.subtotal.tax.push( st_tax.toFixed(2) );
  
                    tempData.subtotal.all_price += st_price;
                    tempData.subtotal.all_tax += st_price * tax_percent;
                    tempData.grand_total.push( (st_price - detail.discount + st_tax).toFixed(2) );

                    // persiapan Total Semua
                    manual_calc_resp.total_netto += st_price;
                    manual_calc_resp.total_rounding += detail.rounding;
                    manual_calc_resp.total_tax += st_tax;
                    manual_calc_resp.total_billing += (st_price - detail.discount + st_tax);
                  }
                } else {
                  hu_price = 0;
                  tempData.qty_hu.push(0)
                  tempData.hu_labels.push({
                    amount_uom: this_hu[unit].amount_uom,
                    item_unit_id: this_hu[unit].item_unit_id,
                    unit: unit,
                  });

                  temp_listprice = {
                    price: hu_price.toFixed(2),
                    unit: unit,
                    rounding: 0,
                  }
                    
                  tempData.list_price.push( temp_listprice );
                  tempData.reg_disc.push( 0 );
                  
                  let st_price = 0;
                  let st_tax = 0;
                  tempData.subtotal.price.push( st_price.toFixed(2) );
                  tempData.subtotal.tax.push( st_tax.toFixed(2) );
                  tempData.grand_total.push( (st_price - 0 + st_tax).toFixed(2) );

                  // persiapan Total Semua
                  manual_calc_resp.total_netto += st_price;
                  manual_calc_resp.total_rounding += 0;
                  manual_calc_resp.total_tax += st_tax;
                  manual_calc_resp.total_billing += (st_price - 0 + st_tax);
                }
              }


              tempData.subtotal.all_price = tempData.subtotal.all_price.toFixed(2);
              tempData.subtotal.all_tax = tempData.subtotal.all_tax.toFixed(2);
              tempData.items_hu = tempData.qty_hu.join('.');
              tempData.items_qty_hu = tempData.qty_hu;
              tempData.colWidth = (12/tempData.qty_hu.length);
              // console.log("tempData", tempData)
              
              data.push(tempData)
            });
            this.table.data = data;
            this.manual_calc_resp = manual_calc_resp;
            // // console.log(this.table.data)
            // console.log("this.table.data", this.table.data)
            // console.log("resolve 10 lho - 2611")
            resolve("resolve lho");
          } else {
            // console.log(resp);
            this.$router.push({ name: "sales-order" });            
            reject("reject lho");
          }
        }); 
      });
    },
  },
  filters: {
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("DD MMM YYYY");
      }
    },
    formatCurrency(value) {
      if (value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
  },
  watch: {
    selectedCustomer: function () {
      this.selectedTerritory = this.optionTerritory.filter((v) => {
        return v.id == this.dataCustomerOrder.territory_id;
      });
      this.optionTerritory = this.selectedTerritory;
      this.selectedTerritory = this.selectedTerritory[0];
      
      this.salesPersonal.selected = this.salesPersonal.option.filter((v) => {
        return v.ID == this.dataCustomerOrder.sales_personal_id;
      });
      this.salesPersonal.option = this.salesPersonal.selected;
      this.salesPersonal.selected = this.salesPersonal.selected[0];
      
      this.salesChannel.selected = this.salesChannel.option.filter((v) => {
        return v.ID == this.salesPersonal.selected.SalesChannelId;
      });
      this.salesChannel.option = this.salesChannel.selected;
      this.salesChannel.selected = this.salesChannel.selected[0];
      
      this.customerCategory.selected = this.customerCategory.option.filter((v) => {
        return v.ID == this.selectedCustomer.customer_category_id;
      });
      this.customerCategory.option = this.customerCategory.selected
      this.customerCategory.selected = this.customerCategory.selected[0]
      
      this.pricingGroup.selected = this.pricingGroup.options.filter((v) => {
        return v.ID == this.selectedCustomer.pricing_group_id;
      });
      this.pricingGroup.options = this.pricingGroup.selected
      this.pricingGroup.selected = this.pricingGroup.selected[0]
      
      this.paymentTerm.selected = this.paymentTerm.option.filter((v) => {
        return v.id == this.selectedCustomer.payment_term_id;
      });
      this.paymentTerm.selected = this.paymentTerm.selected[0];
    },
    dateCo: function() {
      
      let today, dateCo, rDeliveryDate, duedate;
      today = moment(new Date()).format("MM/DD/YYYY");
      today = new Date(today);
      if(this.dateCo != null) {
        dateCo = moment(this.dateCo).format("MM/DD/YYYY");      
        dateCo = new Date(dateCo);
        if(dateCo < today && !this.editDate) {
          this.dateCo = today;
          this.$vs.notify({
            title: "Warning",
            text: "CO date cannot backdated",
            color: "warning",
            position: "top-right"
          });
        }
        if(this.rDeliveryDate != null && !this.editDate) {
          rDeliveryDate = moment(this.rDeliveryDate).format("MM/DD/YYYY");
          rDeliveryDate = new Date(rDeliveryDate);
          if(rDeliveryDate < this.dateCo && !this.editDate){
            this.dateCo = today;
            this.$vs.notify({
              title: "Warning",
              text: "CO date cannot greater than Req delivery date",
              color: "warning",
              position: "top-right"
            });
          }
          if(rDeliveryDate == this.dateCo && !this.editDate){
            this.dateCo = today;
            this.$vs.notify({
              title: "Warning",
              text: "CO date cannot greater than Req delivery date",
              color: "warning",
              position: "top-right"
            });
          }
        }
        if(!this.editDate){
          rDeliveryDate = moment(this.rDeliveryDate).format("MM/DD/YYYY");
          rDeliveryDate = new Date(rDeliveryDate);
          rDeliveryDate.setDate( this.dateCo.getDate() + 2) //default RDD H+2 dari dateCO
          this.rDeliveryDate = rDeliveryDate;
        }
      }
    },
    rDeliveryDate: function() {
      
      let today, dateCo, rDeliveryDate, duedate, limit;
      today = moment(new Date()).format("MM/DD/YYYY");
      today = new Date(today);

      if(this.rDeliveryDate != null && !this.editReqDelDateTo) {
        rDeliveryDate = moment(this.rDeliveryDate).format("MM/DD/YYYY");
        rDeliveryDate = new Date(rDeliveryDate);
        if(this.dateCo != null) {
          dateCo = moment(this.dateCo).format("MM/DD/YYYY");      
          dateCo = new Date(dateCo);
          limit = dateCo;
          limit.setDate( limit.getDate() + 1)
          if(rDeliveryDate < limit){
            this.rDeliveryDate = limit;
            this.$vs.notify({
              title: "Warning",
              text: "Request delivery date must greater than CO date",
              color: "warning",
              position: "top-right"
            });
          }
        }
        if(rDeliveryDate < today) {
          this.rDeliveryDate = limit;
          this.$vs.notify({
            title: "Warning",
            text: "Request delivery date cannot backdated",
            color: "warning",
            position: "top-right"
          });
        }
      }
    },
    duedate: function() {
      let today, dateCo, rDeliveryDate, duedate, limit;
      duedate = moment(this.duedate).format("MM/DD/YYYY");
      duedate = new Date(duedate)

      rDeliveryDate = moment(this.rDeliveryDate).format("MM/DD/YYYY");
      rDeliveryDate = new Date(rDeliveryDate);
      
      if(duedate < today && !this.editDueDate){
        this.duedate = rDeliveryDate;
        this.$vs.notify({
          title: "Warning",
          text: "Duedate cannot backdated",
          color: "warning",
          position: "top-right"
        });
      }
      if(duedate < rDeliveryDate && !this.editDueDate){
        this.duedate = rDeliveryDate;
        this.$vs.notify({
          title: "Warning",
          text: "Duedate must equal or greater than delivery date",
          color: "warning",
          position: "top-right"
        });
      }
    },
    isOneTime: function() {
      if(!this.isOneTime){
        
        this.selectedShippTo = {
          id:null,
          CustomerID:"",
          Address:"",
        }
        
        this.selectedBillTo = {
          id:null,
          CustomerID:"",
          Address:"",
        }
        
        this.selectedCustomer.name = "";
        this.selectedCustomer.code = "";
        
        this.resetOTC_data();
        this.showOTCForm = false;
      }else{
        this.showOTCForm = this.edit ? true : false;
        this.getOnetimeCustomer();
      }
    },    
    soType: function() {
      this.calculated = false;
      
      if (this.selectedCustomer.id && !this.isOneTime) {
        if (this.soType.Code == "ZDCBD") {
          this.getCustDeposit(this.selectedCustomer.id);
          this.showCustDeposit = true;
        } else {
          this.showCustDeposit = false;
        }
      } else if (this.isOneTime) {
        this.showCustDeposit = false;
        if (this.soType != null) {
          this.soType = null;
          this.$vs.notify({
            title: "Warning",
            text: "Cannot select CBD type for onetime customer !",
            color: "warning",
            position: "top-right"
          });
          return;
        }
      } else {
        if (this.soType != null) {
          this.soType = null;
          this.$vs.notify({
            title: "Warning",
            text: "Please select customer first !",
            color: "warning",
            position: "top-right"
          });
          return;
        }
      }
    },
  },
  mounted() {
    
    this.$vs.loading();
    Promise.all([
      this.getSOTypes(),
      this.getTerritory_(), 
      this.getOptionZones_(), 
      this.getOptionSalesChannel_(),
      this.getOptionCustomerCategory_(),
      this.getOptionSalesID_(),
      this.getAllDataPricingGroup_(),
      this.getOptionPaymentTerm_(),
      this.getOptionDeliveryType_(),
      this.getItem_(),
    ]).then((r) => {
      // console.log(r);
      this.getData_().then((res) => {
        // console.log(res);
        this.editDate = false
        this.editDueDate = false
        this.editReqDelDateTo = false

        if(this.selectedShippTo.id != null){
          this.$vs.loading.close();
        }
      });
    });
    
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>

<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
.vs-table--thead{
  z-index:-1 !important;
  display: none;
}
</style>
